
[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
}
  
[type="file"] + label {
    position: relative;
    display: inline-block;
    margin: 1em;
    padding: 0.5em 1.5em;
    border: 0;
    border-radius: 30px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    background-color: rgb(29, 162, 216);
    color: #fff;
}

[type="file"]:hover {
    box-shadow: inset 0 -3px 0 rgba(0,0,0,0.15);
}

.modal-container {
    margin: 20px;
}

.title-header {
    font-size: 27px;
    font-weight: 600;
}

.upload-container {
    height: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 25px;
    margin: 25px 10px;
}

.upload-container svg {
    font-size: 40px;
}

.upload-container p {
    font-size: 17px;
    margin-top: 15px;
    font-weight: 530;
}

.upload-default {
    border: 2px dashed rgb(29, 162, 216);
}

.upload-default svg {
    color:rgb(29, 162, 216);
}

.upload-greyed {
    border: 2px dashed #D3D3D3;
}

.upload-greyed svg {
    color: #D3D3D3;
}

.upload-greyed a,
.upload-greyed h6,
.upload-greyed p {
    color: #D3D3D3;
}

.file-uploaded-row {
    background: #e9f0ff;
    list-style: none;
    width: 45%;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.file-uploaded-row svg {
    color:rgb(29, 162, 216);
}

.file-uploaded-row p {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    color: rgb(29, 162, 216);
}

.validation-box {
    border-radius: 5px;
    margin-bottom: 10px;
    min-height: 100px;
}

.v-box-error {
    border: 2px solid #EA7B7B;
}

.v-box-success {
    border: 2px solid #5cb85c;
}

.validation-header {
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.validation-header svg {
    font-size: 17px;
}

.validation-header h6 {
    font-size: 17px;
    font-weight: 600;
    margin-left: 5px;
}

.header-error {
    background: #FAE0E0;
}

.header-error h6,
.header-error svg {
    color: #DC2323;
}

.header-correct,
.header-correct svg,
.header-correct h6 {
    background: #bfffd8;
    color: #5cb85c;
}

.validation-messages-box {
    padding: 10px 15px;
}

.validation-message {
    display: flex;
    align-items: center;
}

.validation-message span {
    margin-left: 10px;
}

.validation-error svg,
.validation-error span {
    color: #DC2323;
}

.validation-success svg,
.validation-success span {
    color: #5cb85c;
}