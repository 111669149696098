* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif;
}

.leaflet-container {
  width: 100wh; 
  height: 150vh;
  z-index: 0;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-item {
  width: 48;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-dropdown {
  position: absolute;
  top: 50px;
  width: 350px;
  max-height: 400px;
  transform: translateX(15%);
  background-color: #ffffff;
  border: 1px solid #474a4d;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
}

.custom-menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: #151616 500ms;
  padding: 0.4rem;
  color: #000;
}

.custom-variable-item {
  height: 35px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0.3rem;
  color: #000;
}

.custom-variable-item:hover {
  background-color: #d6d6d6;
}

.custom-time-menu {
  width: 90%;
  margin-left: 10px;
}

.custom-menu {
  width: 100%;
}

.custom-menu-item:hover {
  background-color: #d6d6d6;
}

.custom-menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.custom-menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}

.custom-menu-primary-exit {
  position: absolute;
}


.custom-menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}

/* organization menu animation */

.custom-menu-org-enter {
  transform: translateX(110%);
}
.custom-menu-org-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.custom-menu-org-exit {

}
.custom-menu-org-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}

/* variables menu animation */

.custom-menu-variables-enter {
  transform: translateX(110%);
}
.custom-menu-variables-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.custom-menu-variables-exit {

}
.custom-menu-variables-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}

/* time menu animation */

.custom-menu-time-enter {
  transform: translateX(110%);
}
.custom-menu-time-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.custom-menu-time-exit {

}
.custom-menu-time-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}

.dropdown-right-icon {
  margin-left: auto;
}

.dropdown-left-icon {
  --button-size: 30px;
  width: var(--button-size);
  height: var(--button-size);
  padding: 3px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.dropdown-left-icon:hover {
  filter: brightness(1.2);
}

.leaflet-draw-actions a {
  background-color: #ffffff !important;
  color: #000 !important;
}

.leaflet-draw-actions a:hover {
  background-color: #ffffff !important;
  color: #3a93cf !important;
}

.date-pick-container {
  display: flex;
  flex-direction: column;
}